import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { useGetProjects } from '../hooks/useGetProjects'
import { ProjectContext } from '../providers/project.provider'
import { useRealmAuth } from '../providers/realm-auth.provider'

const INITIAL_STATE = [{ nm: 'New' }]

const ExistingProjectSelect = (props) => {
  const { currentProject } = useContext(ProjectContext)
  const { setCurrentProject } = props
  const { currentUser } = useRealmAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [projectName, setProjectName] = useState('New')
  const [projects, setProjects] = useState(INITIAL_STATE)
  const { data, isFetching, error } = useGetProjects(
    currentUser?.customData?.email
  )

  const handleChange = (event) => {
    setProjectName(event.target.value)
    const selectedProject = projects.filter(
      (project) => project.nm === event.target.value
    )[0]
    setCurrentProject(selectedProject)
  }

  useEffect(() => {
    if (!isFetching && data) {
      const update = INITIAL_STATE.concat(data)
      setProjects(update)
      if (currentProject.nm) setProjectName(currentProject.nm)
    }
    if (!currentProject.hasOwnProperty('nm')) {
      setProjectName('New')
    }
  }, [data, isFetching, currentProject])

  if (isFetching) return <LinearProgress />

  if (error) {
    enqueueSnackbar(error.message, {
      variant: 'error',
    })
  }

  return (
    <FormControl sx={{ minWidth: '40ch' }}>
      <InputLabel id='demo-simple-select-label'>Existing project</InputLabel>
      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        label='Existing project'
        value={projectName}
        onChange={handleChange}
      >
        {projects.map((project) => (
          <MenuItem key={project.nm} value={project.nm}>
            {project.nm}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ExistingProjectSelect
