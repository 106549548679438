import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import MenuItem from '@mui/material/MenuItem'

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined'
import { useRealmAuth } from '../providers/realm-auth.provider'

import DropDownButton from './dropdown-button.component'

const iconStyle = { marginRight: 4 }

const AccountMenuButton = () => {
  const {
    currentUser,
    actions: { handleLogout },
  } = useRealmAuth()

  return (
    <DropDownButton
      label={currentUser?.profile?.name}
      startIcon={<PersonOutlineOutlinedIcon />}
    >
      <MenuItem component={RouterLink} to='/settings'>
        <SettingsOutlinedIcon style={iconStyle} /> Settings
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <MeetingRoomOutlinedIcon style={iconStyle} /> Logout
      </MenuItem>
    </DropDownButton>
  )
}

export default AccountMenuButton
