import { React } from 'react'
import Paper from '@mui/material/Paper'
import { Container } from '@mui/system'
import { Divider, Grid, Typography } from '@mui/material'

export default function ProjectInstructions() {
  return (
    <Paper elevation={6} className='tour-welcome'>
      <Container>
        <Grid container direction={'column'} spacing={2} wrap={'wrap'}>
          <Grid item>
            <Typography variant='h5'>Welcome to mLocust 2.0</Typography>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography variant='h6'>Step 1: Project Configuration</Typography>
            <Typography>
              Set your project context by selecting an existing project or
              manually filling out the project configuration and then click
              Submit. After you hit submit, your cluster will be created on the
              background and will take about 5 mins to be created.
            </Typography>
            <Typography>
              <b>
                {' '}
                Please do not use any sensitive passwords in your SRV link as
                these are stored in plain text{' '}
              </b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6'>Step 2: Requirements</Typography>
            <Typography>
              This step is optional. If you need additional python packages,
              include them here.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6'>Step 3: Locust File</Typography>
            <Typography>
              Create your Locust file using one of the baseline templates. You
              have 2 templates to get you started. You can also upload your
              local file.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6'>Step 4: Scale Cluster</Typography>
            <Typography>
              After you are done finalizing your Locust file, you can scale your
              cluster. The first run must be with 1 worker, so you can test that
              everything is working correctly and approximate the total number
              of workers you'll need.
            </Typography>
          </Grid>
          <Grid item mb={2}></Grid>
        </Grid>
      </Container>
    </Paper>
  )
}
