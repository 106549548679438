import React, { useContext, useEffect } from 'react'
import { NavigationContext } from '../providers/navigation.provider'

import { Divider, Grid, Typography, Container } from '@mui/material'

const WhatsNewPage = () => {
  const { setDisabled } = useContext(NavigationContext)

  useEffect(() => {
    setDisabled(true)
  }, [setDisabled])
  return (
    <Container>
      <Grid container direction={'column'} spacing={2} wrap={'wrap'}>
        <Grid item>
          <Typography variant='h5'>
            mLocust Version 2.0 Release Notes
          </Typography>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Typography variant='h6'>March 9th 2023</Typography>
          <Typography variant='body2'>
            In-browser editor of locust files
          </Typography>
          <Typography variant='body2'>Support for locust templates</Typography>
          <Typography variant='body2'>
            Ability to save projects for future reuse
          </Typography>
          <Typography variant='body2'>
            Reduced overall deployment times by leveraging a baseline container
            image rather than rebuilding a new image every time
          </Typography>
          <Typography variant='body2'>
            Converted all k8s commands to API's to better handle concurrency
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default WhatsNewPage
