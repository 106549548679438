import { useContext } from 'react'
import { Stepper, Step, Box, StepButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'

//Context
import { NavigationContext } from '../providers/navigation.provider'

const steps = [
  {
    page: 'project-config',
    label: 'Project configuration',
    link: '/project-config',
  },
  {
    page: 'requirements-file',
    label: 'requirements.txt',
    link: '/requirements-file',
  },
  { page: 'locust-file', label: 'Locust File', link: '/locust-file' },
  { page: 'scale-cluster', label: 'Scale Cluster', link: '/scale-cluster' },
]

const StepNavigation = (props) => {
  const { currentStep, stepsCompletion, disabled } =
    useContext(NavigationContext)

  const navigate = useNavigate()

  if (disabled) return null

  return (
    <Box sx={{ width: '80%', marginBottom: '20px' }} className='tour-steps'>
      <Stepper nonLinear activeStep={currentStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={step.label} completed={stepsCompletion[index]}>
            <StepButton color='inherit' onClick={() => navigate(step.link)}>
              {step.label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default StepNavigation
