import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'

// Material UI
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from '@mui/material'
import ComputerOutlinedIcon from '@mui/icons-material/Computer'
import { useSnackbar } from 'notistack'
import { Box } from '@mui/system'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import { useSaveProject } from '../hooks/useSaveProject'
import { useDeleteProject } from '../hooks/useDeleteProject'

// Custom Components
import SmallTextField from './common/small-text-field'
import ExistingProjectBox from '../components/existing-project-box.component'

import { useRealmAuth } from '../providers/realm-auth.provider'
import { ProjectContext } from '../providers/project.provider'
import { NavigationContext } from '../providers/navigation.provider'

import zones from '../zones.json'
import Confirmation from './confirmation-diaglog.component'
import { useQueryClient } from 'react-query'

const validationSchema = yup.object({
  projectName: yup
    .string('Enter your Project Name')
    .matches(
      /^[a-zA-Z0-9-]+$/,
      'Only letters numbers and hyphens allowed in Project Name'
    )
    .required('Project Name is required'),
  zone: yup.string('Enter your zone').required('Zone is required'),
  srv: yup
    .string('Enter your Atlas SRV')
    .matches(/^mongodb\+srv:\/\/.+:.+@/, 'Must be a valid MongoDB SRV Record')
    .required('Atlas SRV is required'),
  databaseName: yup
    .string('Enter your database name')
    .required('Database name is required'),
  collectionName: yup
    .string('Enter your collection name')
    .required('Collection name is required'),
})

const ProjectConfigBox = () => {
  const navigate = useNavigate()
  const mutation = useSaveProject()
  const deleteMutation = useDeleteProject()
  const { enqueueSnackbar } = useSnackbar()
  const [disabled, setDisabled] = useState(false)
  const { currentProject, setCurrentProject } = useContext(ProjectContext)
  const { setStepsCompletion } = useContext(NavigationContext)
  const { currentUser } = useRealmAuth()
  const [open, setOpen] = useState(false)
  const queryClient = useQueryClient()

  const handleProjectDelete = async () => {
    setOpen(false)
    try {
      await deleteMutation.mutateAsync(currentProject)
      if (deleteMutation.isError) {
        enqueueSnackbar(deleteMutation.error.message, {
          variant: 'error',
        })
      } else {
        enqueueSnackbar('Project was deleted!', {
          variant: 'success',
        })
        newProject()
        queryClient.invalidateQueries('getProjects')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const formik = useFormik({
    initialValues: {
      projectName: '',
      zone: '',
      srv: '',
      databaseName: '',
      collectionName: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const projectData = {
        user: currentUser?.customData?.email,
        nm: values.projectName,
        zone: values.zone,
        srv: values.srv,
        db: values.databaseName,
        coll: values.collectionName,
      }
      try {
        await mutation.mutateAsync(projectData)
        localStorage.setItem('project', JSON.stringify(projectData))
        enqueueSnackbar(
          'Project has been submitted and cluster is being created!',
          {
            variant: 'success',
          }
        )
        enqueueSnackbar(
          'It may take up to 5 min for the cluster to be created!',
          {
            variant: 'warning',
            autoHideDuration: 30000,
          }
        )
        setCurrentProject(projectData)
        navigate('/locust-file')
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      }
    },
  })

  const clear = () => {
    formik.resetForm({
      values: {
        projectName: '',
        zone: '',
        srv: '',
        databaseName: '',
        collectionName: '',
      },
    })
    localStorage.removeItem('project')
  }

  const newProject = () => {
    setCurrentProject({})
    setStepsCompletion({ 0: false, 1: false, 2: false, 3: false })
    setDisabled(false)
    clear()
  }

  const setSelectedProject = (currentProject) => {
    if (currentProject.nm === 'New') {
      newProject()
    } else {
      setCurrentProject(currentProject)
      setDisabled(true)
      localStorage.setItem('project', JSON.stringify(currentProject))
    }
  }

  useEffect(() => {
    const updateForm = (project) => {
      formik.resetForm({
        values: {
          projectName: project.nm,
          zone: project.zone,
          srv: project.srv,
          databaseName: project.db,
          collectionName: project.coll,
        },
      })
    }

    if (
      currentProject.nm !== undefined &&
      formik.values.projectName !== currentProject.nm
    ) {
      updateForm(currentProject)
    }
  }, [currentProject, formik])

  return (
    <Container maxWidth={false} className='tour-config' sx={{ marginLeft: 2 }}>
      <Box component='form' onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Card elevation={6} sx={{ height: '100%', width: '100%' }}>
            <Grid
              container
              alignItems={'center'}
              justifyContent={'space-between'}
              direction='row'
            >
              <Grid item>
                <CardHeader title='Project Configuration' />
              </Grid>
              <Grid item>
                <ExistingProjectBox setCurrentProject={setSelectedProject} />
              </Grid>
            </Grid>

            <CardContent>
              <Divider sx={{ mb: 2 }} />
              <Grid
                container
                direction='column'
                spacing={2}
                alignItems={'center'}
              >
                <Grid item>
                  <SmallTextField
                    disabled={disabled}
                    sx={{ minWidth: '40ch' }}
                    id='projectName'
                    name='projectName'
                    label='Project Name'
                    value={formik.values.projectName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.projectName &&
                      Boolean(formik.errors.projectName)
                    }
                    helperText={
                      formik.touched.projectName && formik.errors.projectName
                    }
                  ></SmallTextField>
                </Grid>
                <Grid item>
                  <FormControl required sx={{ minWidth: '40ch' }}>
                    <InputLabel id='zone-label'>Zone</InputLabel>
                    <Select
                      labelId='zone-label'
                      id='zone'
                      name='zone'
                      label='zone'
                      value={formik.values.zone}
                      onChange={formik.handleChange}
                    >
                      {zones.map((zone) => (
                        <MenuItem key={zone.value} value={zone.value}>
                          {zone.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText
                      error={formik.touched.zone && Boolean(formik.errors.zone)}
                      id='zone-helper-text'
                    >
                      {formik.touched.zone && formik.errors.zone}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item>
                  <SmallTextField
                    sx={{ minWidth: '40ch' }}
                    id='srv'
                    name='srv'
                    label='Atlas SRV'
                    value={formik.values.srv}
                    onChange={formik.handleChange}
                    error={formik.touched.srv && Boolean(formik.errors.srv)}
                    helperText={formik.touched.srv && formik.errors.srv}
                  ></SmallTextField>
                </Grid>
                <Grid item>
                  <SmallTextField
                    sx={{ minWidth: '40ch' }}
                    id='databaseName'
                    name='databaseName'
                    label='Database Name'
                    value={formik.values.databaseName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.databaseName &&
                      Boolean(formik.errors.databaseName)
                    }
                    helperText={
                      formik.touched.databaseName && formik.errors.databaseName
                    }
                  ></SmallTextField>
                </Grid>
                <Grid item>
                  <SmallTextField
                    sx={{ minWidth: '40ch' }}
                    id='collectionName'
                    name='collectionName'
                    label='Collection Name'
                    value={formik.values.collectionName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.collectionName &&
                      Boolean(formik.errors.collectionName)
                    }
                    helperText={
                      formik.touched.collectionName &&
                      formik.errors.collectionName
                    }
                  ></SmallTextField>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid container justifyContent={'flex-end'} mt={2}>
            {mutation.isLoading && (
              <Grid item mr={2}>
                <CircularProgress />
              </Grid>
            )}
            <Grid item mr={2}>
              <Button
                startIcon={<ComputerOutlinedIcon />}
                variant='contained'
                type='submit'
                disabled={mutation.isLoading}
              >
                Submit Project and Continue
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<DeleteOutlinedIcon />}
                variant='contained'
                onClick={() => setOpen(true)}
              >
                Delete Project
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {open && (
        <Confirmation
          title={'Are you sure?'}
          open={open}
          desc={'You are about to delete this project.'}
          severity={'error'}
          handleSubmit={handleProjectDelete}
          handleCancel={() => setOpen(false)}
        />
      )}
    </Container>
  )
}

export default ProjectConfigBox
