import { useQuery } from 'react-query'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const getProjects = async (userEmail) => {
  let res = await fetch(`${BACKEND_URL}/getProjects?user=${userEmail}`)
  res = await res.json()

  if (!res) {
    throw new Error('Server did not respond')
  } else if (res.type === 'error') {
    throw new Error(res.msg)
  }

  return res
}

export const useGetProjects = (userEmail) => {
  return useQuery(['getProjects', userEmail], () => getProjects(userEmail), {
    enabled: !!userEmail,
    refetchOnWindowFocus: false,
  })
}
