import React, { useState, useContext, useEffect } from 'react'
import { Grid, Typography } from '@mui/material'

import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

//Context
import { NavigationContext } from '../providers/navigation.provider'
import { ProjectContext } from '../providers/project.provider'

import { useGetDebugLogs } from '../hooks/useGetDebugLogs'
import Alert from '../components/alert.component'
import Editor from '../components/editor.component'
import { Container } from '@mui/system'

const DebugPage = () => {
  const navigate = useNavigate()
  const { setDisabled } = useContext(NavigationContext)
  const { currentProject } = useContext(ProjectContext)
  const { enqueueSnackbar } = useSnackbar()
  const [content, setContent] = useState('')
  const { data, isFetching, error } = useGetDebugLogs(
    currentProject.user,
    currentProject.nm,
    currentProject.zone
  )

  const selectProject = () => {
    navigate('/')
  }

  useEffect(() => {
    setDisabled(true)
  }, [setDisabled])

  useEffect(() => {
    if (!isFetching && data) {
      setContent(data)
    }
  }, [data, isFetching, setContent])

  if (error) {
    enqueueSnackbar(error.message, {
      variant: 'error',
    })
  }

  if (currentProject.nm === undefined)
    return (
      <Alert
        title={'No project selected'}
        desc={'You must first create or select a project'}
        severity={'error'}
        actionText={'Select Project'}
        actionFunction={selectProject}
      />
    )

  return (
    <Container>
      <Grid container direction={'column'} spacing={2}>
        <Grid item>
          <Typography variant='h6'>Pods details</Typography>
          {content.pods?.map((pod, index) => (
            <Typography key={index}>{pod}</Typography>
          ))}
        </Grid>
        <Grid item>
          <Editor content={content.logs} name={'Pod logs'} readOnly />
        </Grid>
      </Grid>
    </Container>
  )
}

export default DebugPage
