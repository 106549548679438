import React, { useEffect, useContext } from 'react'

// Material UI

import { Grid, Container } from '@mui/material'

// Custom Components
import ScaleClusterBox from '../components/scale-cluster-box.component'
import LocustInstructions from '../components/locust-instructions-box.component'

//Context
import { NavigationContext } from '../providers/navigation.provider'
import { ProjectContext } from '../providers/project.provider'
import Alert from '../components/alert.component'
import { useNavigate } from 'react-router-dom'

const ScaleClusterPage = () => {
  const { setCurrentStep, setDisabled } = useContext(NavigationContext)
  const { currentProject, setCurrentProject } = useContext(ProjectContext)
  const navigate = useNavigate()

  const selectProject = () => {
    navigate('/')
  }

  useEffect(() => {
    if (currentProject.nm !== undefined) {
      setCurrentStep(3)
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [setCurrentStep, setDisabled, currentProject])

  if (currentProject.nm === undefined)
    return (
      <Alert
        title={'No project selected'}
        desc={'You must first create or select a project'}
        severity={'error'}
        actionText={'Select Project'}
        actionFunction={selectProject}
      />
    )

  return (
    <Container maxWidth={false}>
      <Grid container direction='column' spacing={4}>
        <Grid item>
          <LocustInstructions />
        </Grid>
        <Grid item>
          <ScaleClusterBox
            currentProject={currentProject}
            setCurrentProject={setCurrentProject}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default ScaleClusterPage
