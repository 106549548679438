import React, { useContext, useEffect } from 'react'
import GoogleOneTapLogin from 'react-google-one-tap-login'
import { useNavigate } from 'react-router-dom'

// Contexts
import { useRealmAuth } from '../providers/realm-auth.provider'
import { NavigationContext } from '../providers/navigation.provider'
// Custom Components

// Material UI
import Welcome from '../components/welcome.component'

const SignInPage = () => {
  const {
    isLoggedIn,
    actions: { handleCredentialsResponse },
  } = useRealmAuth()
  const navigate = useNavigate()
  const { setDisabled } = useContext(NavigationContext)

  useEffect(() => {
    setDisabled(true)
  }, [setDisabled])

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn, navigate])

  if (isLoggedIn) {
    return null
  }

  return (
    <>
      <Welcome />
      <GoogleOneTapLogin
        onError={(error) => console.log(error)}
        googleAccountConfigs={{
          client_id:
            '201566393184-mjj2303vkl08cvij85qrgijt7pd11dhq.apps.googleusercontent.com',
          callback: handleCredentialsResponse,
          cancel_on_tap_outside: false,
        }}
      />
    </>
  )
}

export default SignInPage
