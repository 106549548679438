import React, { useState, createContext, useEffect, useContext } from 'react'

import { useGetProjectStatus } from '../hooks/useGetProjectStatus'
import { ProjectContext } from '../providers/project.provider'
import { useRealmAuth } from '../providers/realm-auth.provider'
import { useSnackbar } from 'notistack'

// Create the context
//Note: Can update step completion async (when the job completes with a callback possibly) or by component
export const NavigationContext = createContext({
  currentStep: 0,
  setCurrentStep: () => {},
  stepsCompletion: { 0: false, 1: false, 2: false, 3: false },
  setStepsCompletion: () => {},
})

export const NavigationProvider = ({ children }) => {
  const { currentUser } = useRealmAuth()
  const { enqueueSnackbar } = useSnackbar()

  const [currentStep, setCurrentStep] = useState(0)
  const [stepsCompletion, setStepsCompletion] = useState(0)
  const [disabled, setDisabled] = useState(true)
  const { currentProject, setCurrentProject } = useContext(ProjectContext)

  let { data, error } = useGetProjectStatus(
    currentUser?.customData?.email,
    currentProject?.nm
  )

  if (error) {
    enqueueSnackbar(error.message, {
      variant: 'error',
    })
  }

  useEffect(() => {
    if (data) {
      setCurrentProject(data)
      setStepsCompletion({
        0: true,
        1: data.reqt ? true : false,
        2: data.locust ? true : false,
        3: data.status === 'RUNNING' ? true : false,
      })
    }
  }, [data, setCurrentProject])

  // Store the data in the context
  const navigationData = {
    currentStep,
    setCurrentStep,
    stepsCompletion,
    setStepsCompletion,
    disabled,
    setDisabled,
  }

  return (
    <NavigationContext.Provider value={navigationData}>
      {children}
    </NavigationContext.Provider>
  )
}
