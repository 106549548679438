import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

// Contexts
import { useRealmAuth } from '../providers/realm-auth.provider'

// Custom Components
import ProtectedRoute from './protected-route.component'

// Pages
import ProjectConfigPage from '../pages/project-config.component'
import RequirementsFilePage from '../pages/requirements-file.component'
import SignInPage from '../pages/sign-in.component'
import AuthPage from '../pages/auth.component'
import SettingsPage from '../pages/settings.component'
import LocustFilePage from '../pages/locust-file.component'
import ScaleClusterPage from '../pages/scale-cluster.component'
import WhatsNewPage from '../pages/whats-new.component'
import DebugPage from '../pages/debug.component'

const PageRouter = () => {
  const { isLoggedIn } = useRealmAuth()

  const defaultProtectedRouteProps = {
    isAuthenticated: isLoggedIn,
    isAllowed: isLoggedIn,
    authenticationPath: '/signin',
    restrictedPath: '/signin',
  }

  return (
    <Routes>
      <Route exact path='/signin' element={<SignInPage />} />
      <Route exact path='/auth' element={<AuthPage />} />
      <Route element={<ProtectedRoute {...defaultProtectedRouteProps} />}>
        <Route path='/' element={<Navigate to='/project-config'></Navigate>} />
        <Route path='/project-config' element={<ProjectConfigPage />} />
        <Route path='/locust-file' element={<LocustFilePage />} />
        <Route path='/requirements-file' element={<RequirementsFilePage />} />
        <Route path='/scale-cluster' element={<ScaleClusterPage />} />
        <Route path='/debug' element={<DebugPage />} />
        <Route path='/new-features' element={<WhatsNewPage />} />
        <Route path='/settings' element={<SettingsPage />} />
      </Route>
    </Routes>
  )
}

export default PageRouter
