import { useQuery } from 'react-query'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const getProjectStatus = async (userEmail, projectName) => {
  let res = await fetch(
    `${BACKEND_URL}/getProjectStatus?user=${userEmail}&nm=${projectName}`
  )
  res = await res.json()

  if (!res) {
    throw new Error('Server did not respond')
  } else if (res.type === 'error') {
    throw new Error(res.msg)
  }

  return res
}

export const useGetProjectStatus = (userEmail, projectName) => {
  return useQuery(
    ['getProjectStatus', userEmail, projectName],
    () => getProjectStatus(userEmail, projectName),
    {
      enabled: !!userEmail && !!projectName,
      refetchOnWindowFocus: false,
      refetchInterval: 30 * 1000,
    }
  )
}
