import React, { useEffect, useState } from 'react'

// Material UI
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'

const Welcome = () => {
  const [brave, setBrave] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const braveBrowser =
        (await (navigator.brave && (await navigator.brave.isBrave()))) || false

      setBrave(braveBrowser)
    }
    fetchData()
  }, [])

  return (
    <Grid container alignItems={'center'} direction={'column'} spacing={2}>
      <Grid item>
        <Typography variant='h4'>Welcome to mLocust 2.0</Typography>
      </Grid>
      <Grid item>
        <Typography variant='h6'>
          Please sign in with your Google account
        </Typography>
      </Grid>
      <Grid item mt={12}>
        {brave && (
          <Typography variant='body1'>
            If you are using the Brave browser can you turn off the Brave
            Shields for this website, so you can login using Google.
          </Typography>
        )}
        <Typography variant='body1' sx={{ mt: 4 }}>
          If you don't see the Google Login prompt, please delete your cookies
          for this website (you only need to delete the g_state cookie).
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Welcome
