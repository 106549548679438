import * as React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink, MemoryRouter } from 'react-router-dom'
import { StaticRouter } from 'react-router-dom/server'
import { createTheme } from '@mui/material/styles'
import '@fontsource/lexend-deca' // Defaults to weight 400.

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props
  // Map href (MUI) -> to (react-router)
  return <RouterLink data-testid='custom-link' ref={ref} to={href} {...other} />
})

LinkBehavior.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
}

function Router(props) {
  const { children } = props
  if (typeof window === 'undefined') {
    return <StaticRouter location='/'>{children}</StaticRouter>
  }

  return <MemoryRouter>{children}</MemoryRouter>
}

Router.propTypes = {
  children: PropTypes.node,
}

const lexendDeca = {
  fontFamily: 'Lexend Deca',
}

const common = {
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  typography: {
    fontFamily: 'Lexend Deca',
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [lexendDeca],
      },
    },
  },
}

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#00ED64',
    },
    secondary: {
      main: '#00684A',
    },
  },
  ...common,
})

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00ED64',
    },
    secondary: {
      main: '#00684A',
    },
  },
  ...common,
})
