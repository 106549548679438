import { useMutation } from 'react-query'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const deleteCluster = async (email) => {
  let res = await fetch(`${BACKEND_URL}/deleteClusters?user=${email}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  res = await res.json()

  if (!res) {
    throw new Error('Server did not respond')
  } else if (res.type === 'error') {
    throw new Error(res.msg)
  }

  return res
}

export const useDeleteCluster = () => {
  return useMutation((email) => deleteCluster(email))
}
