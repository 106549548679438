import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { RealmAuthProvider } from './providers/realm-auth.provider'
import CustomThemeProvider from './providers/custom-theme.provider'
import { NavigationProvider } from './providers/navigation.provider'
import { ProjectProvider } from './providers/project.provider'

import Dashboard from './components/dashboard.component'

function App() {
  const queryClient = new QueryClient()
  return (
    <SnackbarProvider maxSnack={3}>
      <QueryClientProvider client={queryClient}>
        <CustomThemeProvider>
          <RealmAuthProvider>
            <Router>
              <ProjectProvider>
                <NavigationProvider>
                  <Dashboard />
                </NavigationProvider>
              </ProjectProvider>
            </Router>
          </RealmAuthProvider>
        </CustomThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </SnackbarProvider>
  )
}

export default App
