import { React, useContext, useEffect } from 'react'
import Paper from '@mui/material/Paper'
import { Container } from '@mui/system'
import { useSnackbar } from 'notistack'
import { useQueryClient } from 'react-query'

import { useGetProjectStatus } from '../hooks/useGetProjectStatus'
import { useRealmAuth } from '../providers/realm-auth.provider'
import { Divider, Grid, Typography, Button } from '@mui/material'
import { ProjectContext } from '../providers/project.provider'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'

export default function ProjectStatus() {
  const { currentUser } = useRealmAuth()
  const { enqueueSnackbar } = useSnackbar()

  const { currentProject, setCurrentProject } = useContext(ProjectContext)
  const queryClient = useQueryClient()

  let { data, isFetching, error } = useGetProjectStatus(
    currentUser?.customData?.email,
    currentProject?.nm
  )

  useEffect(() => {
    if (data) {
      setCurrentProject(data)
    }
  }, [data, setCurrentProject])

  if (error) {
    enqueueSnackbar(error.message, {
      variant: 'error',
    })
  }

  const handleRefresh = () => {
    queryClient.invalidateQueries('getProjectStatus')
  }

  if (!data) data = currentProject

  if (currentProject.nm === undefined) return null

  return (
    <Paper elevation={6} sx={{ mb: 4 }}>
      <Container className='tour-status'>
        <Grid container direction={'column'} spacing={2} wrap={'wrap'}>
          <Grid item>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Typography variant='h6'>Project Status</Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'right' }}>
                <Button
                  startIcon={<RefreshOutlinedIcon />}
                  onClick={handleRefresh}
                  sx={{ color: 'text.secondary' }}
                >
                  Refresh
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          {isFetching ? (
            <Grid item>
              <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                Fetching
              </Typography>
              <Typography color={'text.secondary'} variant='subtitle1'>
                Status
              </Typography>
            </Grid>
          ) : (
            <Grid item>
              <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                {data.status}
              </Typography>
              <Typography color={'text.secondary'} variant='subtitle1'>
                Status
              </Typography>
            </Grid>
          )}
          <Grid item>
            {data.endpoint === 'N/A' ? (
              <Typography>{data.endpoint}</Typography>
            ) : (
              <Typography>
                <a
                  style={{ color: '#13AA52' }}
                  target='_blank'
                  href={data.endpoint}
                  rel='noreferrer'
                >
                  {data.endpoint}
                </a>
              </Typography>
            )}

            <Typography color={'text.secondary'} variant='caption'>
              Endpoint
            </Typography>
          </Grid>
          <Grid item mb={2}>
            <Typography>{data.workers ? data.workers : 0}</Typography>
            <Typography color={'text.secondary'} variant='caption'>
              Pods
            </Typography>
          </Grid>
          <Grid item mb={2} sx={{ textAlign: 'right' }}>
            <Typography color={'text.secondary'} variant='caption'>
              Updates Every 30 seconds
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  )
}
