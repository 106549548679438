import React from 'react'

// Material UI
import Grid from '@mui/material/Grid'
import { Typography, Divider, Link } from '@mui/material'

const SUPPORT_EMAIL_LIST =
  process.env.REACT_APP_SUPPORT_EMAIL_LIST || 'eugene.kang@mongodb.com'

const Footer = () => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Divider />
      </Grid>
      <Grid
        item
        container
        xs={12}
        direction='column'
        alignItems='center'
        spacing={1}
      >
        <Grid className='tour-contact-support' item>
          <Typography>
            Contact{' '}
            <Link
              underline='hover'
              target='_blank'
              href={'mailto:' + SUPPORT_EMAIL_LIST.split(',')[0]}
            >
              {SUPPORT_EMAIL_LIST.split(',')[0]}
            </Link>
            {SUPPORT_EMAIL_LIST.split(',')[1] ? (
              <>
                <span> or </span>
                <Link
                  underline='hover'
                  target='_blank'
                  href={'mailto:' + SUPPORT_EMAIL_LIST.split(',')[1]}
                >
                  {SUPPORT_EMAIL_LIST.split(',')[1]}
                </Link>
              </>
            ) : null}{' '}
            for support
          </Typography>
        </Grid>

        <Grid item>
          <Typography>
            Last Build: {process.env.REACT_APP_BUILDTIME || 'Today'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Footer
