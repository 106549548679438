import { useMutation } from 'react-query'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const scaleCluster = async (projectData) => {
  let res = await fetch(`${BACKEND_URL}/scaleCluster`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(projectData),
  })
  res = await res.json()

  if (!res) {
    throw new Error('Server did not respond')
  } else if (res.type === 'error') {
    throw new Error(res.msg)
  }

  return res
}

export const useScaleCluster = () => {
  return useMutation((projectData) => scaleCluster(projectData))
}
