import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

// Material UI
import { Button } from '@mui/material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { useSnackbar } from 'notistack'

// Custom Components
import { useDeleteCluster } from '../hooks/useDeleteCluster'
import { useRealmAuth } from '../providers/realm-auth.provider'
import Confirmation from './confirmation-diaglog.component'

const DeleteCluster = (props) => {
  const { currentUser } = useRealmAuth()
  const { enqueueSnackbar } = useSnackbar()
  const mutation = useDeleteCluster()
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)

  const handleSubmit = async () => {
    setOpen(false)
    try {
      await mutation.mutateAsync(currentUser?.customData?.email)
      if (mutation.isError) {
        enqueueSnackbar(mutation.error.message, {
          variant: 'error',
        })
      } else {
        enqueueSnackbar('Clusters are being deleted!', {
          variant: 'success',
        })
        queryClient.invalidateQueries('getProjectStatus')
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Button
        startIcon={<DeleteOutlinedIcon />}
        variant='contained'
        onClick={() => setOpen(true)}
      >
        Terminate All Recent Clusters
      </Button>
      {open && (
        <Confirmation
          title={'Are you sure?'}
          open={open}
          desc={'All clusters created in the past 24 hrs will be terminated.'}
          severity={'error'}
          handleSubmit={handleSubmit}
          handleCancel={() => setOpen(false)}
        />
      )}
    </>
  )
}

export default DeleteCluster
