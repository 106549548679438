import React, { useReducer, useEffect } from 'react'
import JoyRide, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import { useTheme, Typography } from '@mui/material'

const TOUR_STEPS = [
  {
    target: 'body',
    title: 'Welcome to mLocust 2.0!',
    content: (
      <Typography>
        Brought to you by a team of dedicated and passionate MongoDB Solution
        Consultants! With the vision of providing a one-stop-shop for all of
        your load testing needs.
      </Typography>
    ),
    placement: 'center',
    hideCloseButton: true,
  },
  {
    target: '.tour-welcome',
    title: 'Instructions',
    content: (
      <Typography>
        Please take some time to go through these 4 simple steps to get your
        mLocust cluster up and running!
      </Typography>
    ),
    placement: 'right',
    hideCloseButton: true,
  },
  {
    target: '.tour-steps',
    title: '4 Steps',
    content: (
      <Typography>Check your progress in each step right here.</Typography>
    ),
    placement: 'right',
    hideCloseButton: true,
  },
  {
    target: '.tour-controls',
    title: 'Controls',
    content: (
      <Typography>
        Here you can manage your project by getting its latest status, checking
        the debug logs in case of an issue, as well as deleting the cluster when
        you no longer need it.
      </Typography>
    ),
    placement: 'right',
    hideCloseButton: true,
  },
]

const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
}

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'START':
      return { ...state, run: true }
    case 'RESET':
      return { ...state, stepIndex: 0 }
    case 'STOP':
      return { ...state, run: false }
    case 'NEXT_OR_PREV':
      return { ...state, ...action.payload }
    case 'RESTART':
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      }
    default:
      return state
  }
}

// Tour component
const Tour = () => {
  const theme = useTheme()

  // Tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE)

  useEffect(() => {
    // Auto start the tour if the tour is not viewed before
    if (
      !localStorage.getItem('mainTour') &&
      window.location.pathname === '/project-config'
    ) {
      dispatch({ type: 'START' })
    }
  }, [])

  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    localStorage.setItem('mainTour', '1')
  }

  const callback = (data) => {
    const { action, index, type, status } = data

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed()
      dispatch({ type: 'STOP' })
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      dispatch({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      })
    }
  }

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        showProgress={true}
        showSkipButton={true}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        styles={{
          tooltipContainer: {
            textAlign: 'left',
          },
          buttonBack: {
            marginRight: 10,
          },
          tooltipTitle: {
            textAlign: 'center',
          },
          options: {
            textColor: theme.palette.text.primary,
            primaryColor: theme.palette.primary.main,
            arrowColor: theme.palette.background.paper,
            backgroundColor: theme.palette.background.paper,
          },
        }}
        locale={{
          last: 'End tour',
        }}
      />
    </>
  )
}

export default Tour
