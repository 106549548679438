import React from 'react'

// Material UI
import { Grid, Container } from '@mui/material'
import ExistingProjectSelect from './existing-project-select.component'

// Custom Components

const ExistingProjectBox = (props) => {
  return (
    <Container>
      <Grid container justifyContent='flex-end' alignItems='flex-end' mt={2}>
        <ExistingProjectSelect {...props} />
      </Grid>
    </Container>
  )
}

export default ExistingProjectBox
