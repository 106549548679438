import React, { useState, createContext } from 'react'

// Create the context
//Note: Can update step completion async (when the job completes with a callback possibly) or by component
export const ProjectContext = createContext({
  currentProject: {},
  setCurrentProject: () => {},
})

export const ProjectProvider = ({ children }) => {
  // Read current project from localStorage or maybe from an api
  const project = JSON.parse(localStorage.getItem('project')) || {}

  const [currentProject, setCurrentProject] = useState(project)

  // Store the data in the context
  const projectData = {
    currentProject,
    setCurrentProject,
  }

  return (
    <ProjectContext.Provider value={projectData}>
      {children}
    </ProjectContext.Provider>
  )
}
