import React, { useState, useContext, useEffect } from 'react'
import {
  Grid,
  FormControlLabel,
  Switch,
  Button,
  Card,
  CardContent,
} from '@mui/material'

import { useSnackbar } from 'notistack'

//Context
import { NavigationContext } from '../providers/navigation.provider'
import { CustomThemeContext } from '../providers/custom-theme.provider'

const SettingsPage = () => {
  const { setDisabled } = useContext(NavigationContext)
  const { enqueueSnackbar } = useSnackbar()
  const { currentTheme, setCurrentTheme } = useContext(CustomThemeContext)

  const [theme, setTheme] = useState(currentTheme)
  const toggleTheme = () => {
    const nextTheme = theme === 'light' ? 'dark' : 'light'
    setTheme(nextTheme)
    setCurrentTheme(nextTheme)
  }

  const saveSettings = () => {
    localStorage.setItem('theme', theme)
    enqueueSnackbar('Settings Saved!', {
      variant: 'success',
    })
  }

  useEffect(() => {
    setDisabled(true)
  }, [setDisabled])

  return (
    <Grid container justifyContent='center'>
      <Grid item>
        <Card style={{ margin: 'auto' }}>
          <CardContent>
            <Grid container direction='column' spacing={2} alignItems='center'>
              <Grid item>
                <FormControlLabel
                  label='Dark mode'
                  control={
                    <Switch checked={theme === 'dark'} onClick={toggleTheme} />
                  }
                />
              </Grid>
              {/*                 <Grid item>
                  <Button
                    color="secondary"
                    onClick={() => {
                      localStorage.removeItem("mainTour");
                      localStorage.removeItem("sidebarTour");
                    }}
                  >
                    Reset Tutorial
                  </Button>
                </Grid> */}
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={saveSettings}
                >
                  Save Settings
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default SettingsPage
