import React, { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Material UI
import { Button, Container, Grid } from '@mui/material'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

// Custom Components
import Editor from '../components/editor.component'
import RequirementsFileSelect from '../components/requirements-file-select.component'
import Alert from '../components/alert.component'

//Context
import { NavigationContext } from '../providers/navigation.provider'
import { useGetFileContent } from '../hooks/useGetFileContent'
import { ProjectContext } from '../providers/project.provider'
import { useSaveRequirementsFile } from '../hooks/useSaveRequirementsFile'
import { useRealmAuth } from '../providers/realm-auth.provider'
import { useSnackbar } from 'notistack'
import { useQueryClient } from 'react-query'

const RequirementsFilePage = () => {
  const navigate = useNavigate()
  const { currentUser } = useRealmAuth()
  const { enqueueSnackbar } = useSnackbar()
  const { setCurrentStep, setDisabled } = useContext(NavigationContext)
  const { currentProject, setCurrentProject } = useContext(ProjectContext)
  const [content, setContent] = useState('')
  const [filename, setFilename] = useState('requirements.txt')
  const [fileTrigger, setFileTrigger] = useState(true)
  const { data, isFetching, error } = useGetFileContent(
    currentProject.reqtFiles?.filter((file) => file.nm === filename).length > 0
      ? currentProject.reqtFiles?.filter((file) => file.nm === filename)[0].path
      : currentProject.reqtFiles?.filter(
          (file) => file.nm === 'requirements.txt'
        )[0].path
  )

  const mutation = useSaveRequirementsFile()
  const queryClient = useQueryClient()

  const handleSubmit = async () => {
    //Here we can validate all of the inputs and not move onto the next page until things are finished
    try {
      const fileData = {
        user: currentUser.customData.email,
        nm: currentProject.nm,
        body: content,
      }
      await mutation.mutateAsync(fileData)
      if (mutation.isError) {
        enqueueSnackbar(mutation.error.message, {
          variant: 'error',
        })
      } else {
        enqueueSnackbar('Requirements.txt was saved to Cloud Storage!', {
          variant: 'success',
        })
        queryClient.invalidateQueries('getProjectStatus')
        navigate('/locust-file')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const selectProject = () => {
    navigate('/')
  }

  useEffect(() => {
    if (currentProject.nm !== undefined) {
      setCurrentStep(1)
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [setCurrentStep, setDisabled, currentProject])

  //Added logic to allow changes to content, but prevent state from resetting default
  useEffect(() => {
    if (!isFetching && data && !content) {
      setContent(data)
    } else if (!isFetching && data && fileTrigger) {
      setContent(data)
      setFileTrigger(false)
    }
  }, [data, isFetching, content, setContent, fileTrigger])

  useEffect(() => {
    setFileTrigger(true)
  }, [filename, setFileTrigger])

  if (error) {
    enqueueSnackbar(error.message, {
      variant: 'error',
    })
  }

  if (currentProject.nm === undefined)
    return (
      <Alert
        title={'No project selected'}
        desc={'You must first create or select a project'}
        severity={'error'}
        actionText={'Select Project'}
        actionFunction={selectProject}
      />
    )

  return (
    <Container maxWidth={false}>
      {currentProject && currentProject.nm && (
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <RequirementsFileSelect
              filename={filename}
              setFilename={setFilename}
              onChange={setFileTrigger}
              currentProject={currentProject}
              setCurrentProject={setCurrentProject}
            />
          </Grid>
          <Grid item>
            {data && (
              <Editor
                content={content}
                setContent={setContent}
                name={'requirements.txt File Editor'}
              />
            )}
          </Grid>
          <Grid container justifyContent={'flex-end'} mt={2}>
            <Grid item>
              <Button
                startIcon={<SaveOutlinedIcon />}
                variant='contained'
                onClick={handleSubmit}
              >
                Save File
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default RequirementsFilePage
