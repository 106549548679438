import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = ({
  isAuthenticated,
  isAllowed,
  redirectPath,
  authenticationPath,
  restrictedPath,
  children,
}) => {
  if (!isAuthenticated) {
    redirectPath = authenticationPath
  }
  if (isAuthenticated && !isAllowed) {
    redirectPath = restrictedPath
  }

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />
  }

  return children ? children : <Outlet />
}

export default ProtectedRoute
