import React, { useEffect, useState } from 'react'

import { useRealmAuth } from '../providers/realm-auth.provider'

// Material UI
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material'
import LinearScaleOutlinedIcon from '@mui/icons-material/LinearScaleOutlined'
import { useSnackbar } from 'notistack'

// Custom Components
import SmallTextField from './common/small-text-field'
import { useScaleCluster } from '../hooks/useScaleCluster'

//Hardcoding max workers for now
const maxWokers = 39
const minWorkers = 1

const ScaleClusterBox = (props) => {
  const { currentProject, setCurrentProject } = props
  const adminUsers = process.env.REACT_APP_SUPPORT_EMAIL_LIST.split(',')
  const { currentUser } = useRealmAuth()
  const [workers, setWorkers] = useState(1)
  const [error, setError] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const mutation = useScaleCluster()

  useEffect(() => {
    if (
      (workers > maxWokers &&
        !adminUsers.includes(currentUser.customData.email)) ||
      workers < minWorkers
    ) {
      setError(true)
    } else {
      setError(false)
    }
  }, [workers, adminUsers, currentUser.customData.email])

  const handleWorkers = (e) => {
    setWorkers(e.target.value)
  }

  const handleSubmit = async () => {
    if (!error) {
      try {
        await mutation.mutateAsync(currentProject)
        if (mutation.isError) {
          enqueueSnackbar(mutation.error.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Cluster is being scaled!', {
            variant: 'success',
          })
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      enqueueSnackbar('Number of workers must be between 1 and 39', {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    setCurrentProject({
      ...currentProject,
      replicas: workers,
    })
  }, [workers, setCurrentProject, currentProject])

  return (
    <Grid container spacing={2} sx={{ paddingLeft: 2 }}>
      <Card elevation={6} sx={{ height: '100%', width: '100%' }}>
        <CardHeader title='Scale Cluster' />
        <CardContent>
          <Divider sx={{ mb: 2 }} />
          <Grid container direction='column' spacing={2} alignItems={'center'}>
            <Grid item>
              <SmallTextField
                error={error}
                helperText={`Must be between ${minWorkers} and ${maxWokers}`}
                sx={{ minWidth: '40ch' }}
                label='Total Workers Required'
                value={workers}
                onChange={handleWorkers}
              ></SmallTextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container justifyContent={'flex-end'} mt={2} spacing={2}>
        <Grid item>
          <Button
            startIcon={<LinearScaleOutlinedIcon />}
            disabled={currentProject.status !== 'RUNNING'}
            variant='contained'
            onClick={() => handleSubmit()}
          >
            Scale cluster
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ScaleClusterBox
