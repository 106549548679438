import TextField from '@mui/material/TextField'

const SmallTextField = (props) => {
  return (
    <TextField
      {...props}
      rows={1}
      variant='outlined'
      margin='normal'
      fullWidth
      required
    />
  )
}

SmallTextField.defaultProps = {
  onChange: (e) => console.log(e),
}

export default SmallTextField
