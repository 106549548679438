import React from 'react'
import { Navigate } from 'react-router-dom'
import * as Realm from 'realm-web'

// Material UI

// Custom Components

const AuthPage = () => {
  Realm.handleAuthRedirect()

  return <Navigate to={'/'} replace />
}

export default AuthPage
