import * as Realm from 'realm-web'
import * as jose from 'jose'
import { app } from './app'

export async function login(response) {
  const claims = jose.decodeJwt(response.credential)
  const idToken = response.credential
  const credentials = Realm.Credentials.google({ idToken })
  const user = await app.logIn(credentials)

  return {
    user,
    claims,
  }
}

export function hasLoggedInUser() {
  // Check if there is currently a logged in user
  return app?.currentUser?.isLoggedIn
}

export function getCurrentUser() {
  // Return the user object of the currently logged in user
  return app?.currentUser?.isLoggedIn ? app.currentUser : null
}

export function logoutCurrentUser() {
  // Logout the currently logged in user
  const user = getCurrentUser()
  return user ? app.currentUser.logOut() : null
}
