import { useQuery } from 'react-query'

const getFileContent = async (path) => {
  let res = await fetch(`${path}?date=${new Date().getTime()}`, {
    // TODO - this will have to be fixed at some point
    referrer: '',
    referrerPolicy: 'no-referrer',
    cache: 'no-store',
  })
  res = await res.text()

  if (!res) {
    throw new Error('Server did not respond')
  }

  return res
}

export const useGetFileContent = (path) => {
  return useQuery(['getFileContent', path], () => getFileContent(path), {
    enabled: !!path,
    refetchOnWindowFocus: true,
  })
}
