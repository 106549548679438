import React, { useState, useEffect, useContext } from 'react'

// Material UI
import CssBaseline from '@mui/material/CssBaseline'
import { Grid, CircularProgress, useTheme } from '@mui/material'

// Custom Components
import Header from './header.component'
import Footer from './footer.component'
import PageRouter from './page-router.component'
import StepNavigation from './step-navigation.component'
import ProjectStatus from './project-status.component'

import { NavigationContext } from '../providers/navigation.provider'
import ProjectInstructions from './project-instructions.component'
import CurrentProjectConfig from './current-project-configuration.component'
import Tour from './tour.component'

function Dashboard() {
  const [isAppLoading, setIsAppLoading] = useState(true)
  const { disabled, currentStep } = useContext(NavigationContext)

  const theme = useTheme()

  useEffect(() => {
    // arbitrary wait for Realm Auth to initiate
    setTimeout(() => {
      setIsAppLoading(false)
    }, 1000)
  }, [])

  return (
    <div
      className='App'
      style={{ backgroundColor: theme.palette.background.default }}
    >
      <CssBaseline />
      <Grid
        style={{ height: '100vh', width: '100%', marginLeft: '0px' }}
        container
        spacing={2}
      >
        <Grid item container xs sm direction='column' spacing={2}>
          <Grid item container>
            <Grid item sm={12}>
              <Header />
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item container justifyContent='center'>
              {isAppLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Tour />
                  <StepNavigation />
                  <Grid container direction={'row'} spacing={2} mt={2}>
                    {disabled ? (
                      <Grid item xs={12}>
                        <PageRouter />
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={4}>
                          {currentStep === 0 ? (
                            <>
                              <ProjectStatus />
                              <ProjectInstructions />
                            </>
                          ) : (
                            <>
                              <ProjectStatus />
                              <CurrentProjectConfig />
                            </>
                          )}
                        </Grid>
                        <Grid item xs={8}>
                          <PageRouter />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item container sx={{ marginTop: 'auto' }}>
            <Grid item sm={12} style={{ width: 'inherit' }}>
              <Footer />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Dashboard
