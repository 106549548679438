import React, { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'

// Material UI
import { Grid, Button, Divider } from '@mui/material'
import ComputerOutlinedIcon from '@mui/icons-material/Computer'
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined'

// Custom Components
import AccountMenuButton from './account-menu-button.component'
import DeleteCluster from './delete-cluster.component'

// Contexts
import { useRealmAuth } from '../providers/realm-auth.provider'
import { ProjectContext } from '../providers/project.provider'

const Header = () => {
  const { currentUser } = useRealmAuth()
  const { currentProject, setCurrentProject } = useContext(ProjectContext)

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container xs={12} justifyContent='center'>
        <Grid item container alignItems='center' justifyContent='center'>
          <img
            alt='MongoDB Logo'
            src='Logomark_RGB_Forest-Green.png'
            style={{ width: '80px' }}
          />
          <h1>mLocust 2.0</h1>
        </Grid>
      </Grid>
      {currentUser && (
        <Grid
          item
          container
          justifyContent='center'
          spacing={2}
          className='tour-controls'
        >
          <Grid item>
            <Button
              variant='contained'
              startIcon={<ComputerOutlinedIcon />}
              component={RouterLink}
              to='/'
            >
              {currentProject.nm ? currentProject.nm : 'Project'}
            </Button>
          </Grid>
          <Grid item>
            <DeleteCluster
              currentProject={currentProject}
              setCurrentProject={setCurrentProject}
            />
          </Grid>
          <Grid item className=''>
            <Button
              variant='contained'
              startIcon={<NewReleasesOutlinedIcon />}
              component={RouterLink}
              to='/new-features'
            >
              What's New
            </Button>
          </Grid>
          <Grid item className='tour-user-profiler'>
            <AccountMenuButton />
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Divider />
      </Grid>
    </Grid>
  )
}

export default Header
