import { React, useContext, useEffect } from 'react'
import Paper from '@mui/material/Paper'
import { Container } from '@mui/system'
import { useSnackbar } from 'notistack'
import { Link as RouterLink } from 'react-router-dom'

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { useGetProjectStatus } from '../hooks/useGetProjectStatus'
import { useRealmAuth } from '../providers/realm-auth.provider'
import { Divider, Grid, Typography, Button } from '@mui/material'
import { ProjectContext } from '../providers/project.provider'

function getURL(url) {
  const date = new Date()
  return `${url}?date=${date}`
}

export default function CurrentProjectConfig() {
  const { currentUser } = useRealmAuth()
  const { enqueueSnackbar } = useSnackbar()

  const { currentProject, setCurrentProject } = useContext(ProjectContext)

  let { data, error } = useGetProjectStatus(
    currentUser?.customData?.email,
    currentProject?.nm
  )

  useEffect(() => {
    if (data) {
      setCurrentProject(data)
    }
  }, [data, setCurrentProject])

  if (error) {
    enqueueSnackbar(error.message, {
      variant: 'error',
    })
  }

  if (!data) data = currentProject

  if (currentProject.nm === undefined) return null

  return (
    <Paper elevation={6}>
      <Container>
        <Grid container direction={'column'} spacing={2} wrap={'wrap'}>
          <Grid item>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Typography variant='h6'>Project Configuration</Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'right' }}>
                {currentProject?.status === 'RUNNING' ? (
                  <Button
                    startIcon={<ErrorOutlineOutlinedIcon />}
                    component={RouterLink}
                    sx={{ color: 'text.secondary' }}
                    to='/debug'
                    disabled={currentProject?.status !== 'RUNNING'}
                  >
                    Debug
                  </Button>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Grid container direction={'row'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant='body2'>{data.nm}</Typography>
                <Typography color={'text.secondary'} variant='caption'>
                  Project Name
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>{data.zone}</Typography>
                <Typography color={'text.secondary'} variant='caption'>
                  Zone
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ overflowWrap: 'anywhere' }}>
            <Typography maxWidth='80%' variant='body2'>
              {data.srv}
            </Typography>
            <Typography color={'text.secondary'} variant='caption'>
              Atlas SRV
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2'>
              <a
                style={{ color: '#13AA52' }}
                target='_blank'
                href={getURL(data.reqt)}
                rel='noreferrer'
              >
                {data.reqt
                  ? data.reqt.replace(
                      'https://storage.googleapis.com/mlocust/',
                      ''
                    )
                  : ''}
              </a>
            </Typography>
            <Typography color={'text.secondary'} variant='caption'>
              Requirements.txt
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2'>
              <a
                style={{ color: '#13AA52' }}
                target='_blank'
                href={getURL(data.locust)}
                rel='noreferrer'
              >
                {data.locust
                  ? data.locust.replace(
                      'https://storage.googleapis.com/mlocust/',
                      ''
                    )
                  : ''}
              </a>
            </Typography>
            <Typography color={'text.secondary'} variant='caption'>
              Locust File
            </Typography>
          </Grid>

          <Grid item mb={2}>
            <Grid container direction={'row'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant='body2'>{data.db}</Typography>
                <Typography color={'text.secondary'} variant='caption'>
                  Database
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>{data.coll}</Typography>
                <Typography color={'text.secondary'} variant='caption'>
                  Collection
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  )
}
