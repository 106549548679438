import {
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material'
import { Container } from '@mui/system'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useGetRequirementsFile } from '../hooks/useGetRequirementsFile'
import { useRealmAuth } from '../providers/realm-auth.provider'

function getURL(files, filename) {
  console.log(files, filename)
  const filePath = files.filter((file) => file.nm === filename)[0].path
  const date = new Date()
  return `${filePath}?date=${date}`
}

function getFilePath(files, filename) {
  const filePath = files.filter((file) => file.nm === filename)[0].path
  return filePath
}

const INITIAL_STATE = [
  {
    nm: 'requirements.txt',
    path: 'https://storage.googleapis.com/mlocust/_requirements.txt',
  },
]

const RequirementsFileSelect = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { currentProject, setCurrentProject, filename, setFilename, onChange } =
    props
  const [files, setFiles] = useState(INITIAL_STATE)
  const { currentUser } = useRealmAuth()
  const { data, isFetching, error } = useGetRequirementsFile(
    currentUser?.customData?.email,
    currentProject?.nm
  )

  const handleChange = (event) => {
    setFilename(event.target.value)
    onChange(true)
  }

  useEffect(() => {
    if (!isFetching && data) {
      setFiles(data)

      if (data.length > 1) {
        setFilename(data.filter((file) => file.nm === currentProject.nm)[0].nm)
      }

      if (!currentProject.hasOwnProperty('reqtFiles')) {
        setCurrentProject({
          ...currentProject,
          reqtFiles: data,
        })
      }
    }
  }, [data, isFetching, currentProject, setCurrentProject, setFilename])

  if (isFetching) return <LinearProgress />

  if (error) {
    enqueueSnackbar(error.message, {
      variant: 'error',
    })
  }

  return (
    <Paper elevation={6}>
      <Container>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Typography variant='h6'>Select a requirements.txt file</Typography>
          </Grid>
          <Grid item mb={4}>
            <Grid container direction={'row'} spacing={2} alignItems={'center'}>
              <Grid item>
                <FormControl sx={{ minWidth: '25%' }}>
                  <InputLabel id='demo-simple-select-label'>
                    Requirements.txt
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={filename}
                    label='requirements.txt'
                    onChange={handleChange}
                  >
                    {files.map((file) => (
                      <MenuItem key={file.nm} value={file.nm}>
                        {file.nm}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  <a
                    style={{ color: '#13AA52' }}
                    target='_blank'
                    href={getURL(files, filename)}
                    rel='noreferrer'
                  >
                    {getFilePath(files, filename).replace(
                      'https://storage.googleapis.com/mlocust/',
                      ''
                    )}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  )
}

export default RequirementsFileSelect
