import React, { useContext, useEffect, useState } from 'react'

// Material UI
import { Button, Container, Grid } from '@mui/material'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

// Custom Components
import Editor from '../components/editor.component'
import LocustFileSelect from '../components/locust-file-select.component'
import Alert from '../components/alert.component'

//Context
import { NavigationContext } from '../providers/navigation.provider'
import { useNavigate } from 'react-router-dom'
import { ProjectContext } from '../providers/project.provider'
import { useGetFileContent } from '../hooks/useGetFileContent'
import { useSaveLocustFile } from '../hooks/useSaveLocustFile'
import { useRealmAuth } from '../providers/realm-auth.provider'
import { useSnackbar } from 'notistack'
import { useQueryClient } from 'react-query'

const LocustFilePage = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { currentUser } = useRealmAuth()
  const { currentProject, setCurrentProject } = useContext(ProjectContext)
  const { setCurrentStep, setDisabled } = useContext(NavigationContext)
  const [filename, setFilename] = useState('FindOne')
  const [fileTrigger, setFileTrigger] = useState(true)
  const { data, isFetching, error } = useGetFileContent(
    currentProject.locustFiles?.filter((file) => file.nm === filename).length >
      0
      ? currentProject.locustFiles?.filter((file) => file.nm === filename)[0]
          .path
      : currentProject.locustFiles?.filter((file) => file.nm === 'FindOne')[0]
          .path
  )

  const [content, setContent] = useState('')
  const mutation = useSaveLocustFile()
  const queryClient = useQueryClient()

  const handleSubmit = async () => {
    //Here we can validate all of the inputs and not move onto the next page until things are finished
    try {
      const fileData = {
        user: currentUser.customData.email,
        nm: currentProject.nm,
        body: content,
      }
      await mutation.mutateAsync(fileData)
      if (mutation.isError) {
        enqueueSnackbar(mutation.error.message, {
          variant: 'error',
        })
      } else {
        enqueueSnackbar('Locust file was saved to Cloud Storage!', {
          variant: 'success',
        })
        queryClient.invalidateQueries('getProjectStatus')
        navigate('/scale-cluster')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const selectProject = () => {
    navigate('/')
  }

  useEffect(() => {
    if (currentProject.nm !== undefined) {
      setCurrentStep(2)
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  })

  useEffect(() => {
    if (currentProject.nm !== undefined) {
      setCurrentStep(2)
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [setCurrentStep, setDisabled, currentProject])

  //Added logic to allow changes to content, but prevent state from resetting default
  useEffect(() => {
    if (!isFetching && data && !content) {
      setContent(data)
    } else if (!isFetching && data && fileTrigger) {
      setContent(data)
      setFileTrigger(false)
    }
  }, [data, isFetching, content, setContent, fileTrigger])

  useEffect(() => {
    setFileTrigger(true)
  }, [filename, setFileTrigger])

  if (error) {
    enqueueSnackbar(error.message, {
      variant: 'error',
    })
  }

  if (currentProject.nm === undefined)
    return (
      <Alert
        title={'No project selected'}
        desc={'You must first create or select a project'}
        severity={'error'}
        actionText={'Select Project'}
        actionFunction={selectProject}
      />
    )

  return (
    <Container maxWidth={false}>
      <Grid container direction={'column'} spacing={2}>
        <Grid item>
          <LocustFileSelect
            filename={filename}
            setFilename={setFilename}
            onChange={setFileTrigger}
            currentProject={currentProject}
            setCurrentProject={setCurrentProject}
          />
        </Grid>
        <Grid item>
          <Editor
            content={content}
            setContent={setContent}
            name={'Locust File Editor'}
          />
        </Grid>
        <Grid container justifyContent={'flex-end'} mt={2}>
          <Grid item>
            <Button
              startIcon={<SaveOutlinedIcon />}
              variant='contained'
              onClick={handleSubmit}
            >
              Save File
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default LocustFilePage
