import { useQuery } from 'react-query'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const getDebugLogs = async (userEmail, projectName, zone) => {
  let res = await fetch(
    `${BACKEND_URL}/debug?user=${userEmail}&nm=${projectName}&zone=${zone}`
  )
  res = await res.json()

  if (!res) {
    throw new Error('Server did not respond')
  } else if (res.type === 'error') {
    throw new Error(res.msg)
  }

  return res
}

export const useGetDebugLogs = (userEmail, projectName, zone) => {
  return useQuery(
    ['getProjectStatus', userEmail, projectName, zone],
    () => getDebugLogs(userEmail, projectName, zone),
    {
      enabled: !!userEmail && !!projectName && !!zone,
      refetchOnWindowFocus: false,
      retry: false,
    }
  )
}
