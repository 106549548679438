import React from 'react'

export default function FileUpload(props) {
  const handleChange = (e) => {
    const fileReader = new FileReader()
    fileReader.readAsText(e.target.files[0], 'UTF-8')
    fileReader.onload = (e) => {
      props.onChange({ data: e.target.result })
    }
  }

  return (
    <div>
      <input type='file' onChange={handleChange} />
    </div>
  )
}
