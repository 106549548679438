import { useMutation } from 'react-query'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const saveProject = async (projectData) => {
  let res = await fetch(`${BACKEND_URL}/saveProject`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(projectData),
  })
  res = await res.json()

  try {
    if (!res) {
      throw new Error('Server did not respond')
    } else if (res.type === 'error') {
      throw new Error(res.msg)
    }
    return res
  } catch (error) {
    throw error
  }
}

export const useSaveProject = () => {
  return useMutation((projectData) => saveProject(projectData))
}
