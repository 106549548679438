import React, { useContext } from 'react'
import AceEditor from 'react-ace'
import { Grid, Paper, Typography } from '@mui/material'
import FileUpload from './upload-file.component'

import * as ace from 'ace-builds/src-noconflict/ace'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/keybinding-vscode'
import 'ace-builds/src-noconflict/ext-searchbox'

import { CustomThemeContext } from '../providers/custom-theme.provider'

ace.config.set('basePath', '/node_modules/ace-builds/src-min-noconflict')

const Editor = (props) => {
  const { name, content, setContent, readOnly } = props
  const { currentTheme } = useContext(CustomThemeContext)

  const theme = currentTheme === 'light' ? 'github' : 'monokai'

  const handleFileUpload = (event) => {
    setContent(event.data)
  }

  return (
    <Grid container direction={'column'} spacing={1}>
      <Grid item>
        <Typography variant='h6'>{name}</Typography>
        {!readOnly && <FileUpload onChange={handleFileUpload}></FileUpload>}
      </Grid>
      <Grid item>
        <Paper elevation={6}>
          <AceEditor
            readOnly={readOnly}
            width='100%'
            height='50vh'
            placeholder='Placeholder Text'
            mode='python'
            theme={theme}
            keyboardHandler={'vscode'}
            name={name}
            onChange={setContent}
            fontSize={14}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            value={content}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Editor
