import { React } from 'react'
import Paper from '@mui/material/Paper'
import { Container } from '@mui/system'
import { Divider, Grid, Typography } from '@mui/material'

export default function LocustInstructions() {
  return (
    <Paper elevation={6}>
      <Container>
        <Grid container direction={'column'} spacing={2} wrap={'wrap'}>
          <Grid item>
            <Typography variant='h5'>How to Run Your Test</Typography>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography variant='body2'>
              Note: Scaling your cluster may take a few minutes and redeploying
              locust files may take 1 min. Please be patient. Also, whenever you
              redeploy a new locust file, your cluster will scale back down to 1
              worker where you need to follow the steps starting from Step 1
              below.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6'>Step 1 - Verify Your Logic</Typography>
            <Typography variant='body2'>
              Starting with 1 worker (do not scale the cluster), click on the
              Endpoint on the left to open up the Locust UI. Verify your logic
              works.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6'>
              Step 2 - Find the sweetspot for your workers
            </Typography>
            <Typography variant='body2'>
              If applicable, tweak the batch size for your bulk insert tasks by
              modifying the Host parameter. Figure out what's a reasonable batch
              size. Usually the batch size ranges from 500 to 1k depending on
              document size. An example pipe delimited Host parameter looks like
              the following:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2'>
              mongodb+srv://user:pass@democluster.ngbij.mongodb.net/?retryWrites=true&w=majority|database|collection|batchsize|taskweight1,taskweight2
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2'>
              Lastly, scale the Locust users (make the spawn rate the same as
              users) to find out how many users your worker can support. Check
              the workers tab in Locust to see what the CPU is for your worker.
              The goal is to keep it slightly less than 90%.{' '}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2'>
              Now that your configurations are optimized, calculate how many
              total workers you'll need to achieve your benchmarks.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6'>Step 3 - Scale the workers</Typography>
            <Typography variant='body2'>
              Change the total workers and hit submit. Keep an eye on the Locust
              UI and wait till the workers count in the upper right matches your
              input. When it matches, you can now run your test at scale.
            </Typography>
          </Grid>
          <Grid item mb={2}></Grid>
        </Grid>
      </Container>
    </Paper>
  )
}
