import React, { useEffect, useContext } from 'react'

// Material UI

// Custom Components
import ProjectConfigBox from '../components/project-config-box.component'

//Context
import { NavigationContext } from '../providers/navigation.provider'

const ProjectConfigPage = () => {
  const { setCurrentStep, setDisabled } = useContext(NavigationContext)

  useEffect(() => {
    setCurrentStep(0)
    setDisabled(false)
  }, [setCurrentStep, setDisabled])

  return <ProjectConfigBox />
}

export default ProjectConfigPage
