import { useSnackbar } from 'notistack'
import React, { useEffect, useContext } from 'react'
import { useCustomUserData } from '../hooks/useCustomUserData'

import {
  hasLoggedInUser,
  logoutCurrentUser,
  getCurrentUser,
  login,
} from '../realm/authentication'

import { CustomThemeContext } from './custom-theme.provider'

const INITIAL_VALUE = {
  isLoggedIn: hasLoggedInUser(),
  currentUser: getCurrentUser(),
  actions: {
    handleCredentialsResponse: () => {},
    handleLogout: () => {},
  },
}

// Create a React Context that lets us expose and access auth state
// without passing props through many levels of the component tree
const RealmAuthContext = React.createContext(INITIAL_VALUE)

// Create a React Hook that lets us get data from our auth context
export function useRealmAuth() {
  const context = React.useContext(RealmAuthContext)
  if (!context) {
    throw new Error('useRealmAuth must be used within a RealmAuthProvider')
  }
  return context
}

export const RealmAuthProvider = (props) => {
  const [authState, setAuthState] = React.useState(INITIAL_VALUE)
  const mutation = useCustomUserData()
  const { enqueueSnackbar } = useSnackbar()

  const { setCurrentTheme } = useContext(CustomThemeContext)

  useEffect(() => {
    setAuthState((state) => ({ ...state }))
  }, [setCurrentTheme])

  const handleLogout = async () => {
    const { isLoggedIn } = authState
    if (isLoggedIn) {
      await logoutCurrentUser()
      setAuthState({
        ...authState,
        isLoggedIn: false,
        currentUser: null,
      })
    } else {
      // console.log("can't handleLogout when no user is logged in");
    }
  }

  const handleCredentialsResponse = async (response) => {
    const { user, claims } = await login(response)
    if (user) {
      setAuthState({
        ...authState,
        isLoggedIn: true,
        currentUser: user,
      })

      try {
        await mutation.mutateAsync({ userId: user.id, ...claims })
        if (mutation.isError) {
          enqueueSnackbar(mutation.error.message, {
            variant: 'error',
          })
        } else {
          await user.refreshCustomData()
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  // We useMemo to improve performance by eliminating some re-renders
  const authInfo = React.useMemo(() => {
    const { isLoggedIn, currentUser } = authState
    return { isLoggedIn, currentUser }
  }, [authState])
  return (
    <RealmAuthContext.Provider
      value={{
        ...authInfo,
        actions: { handleLogout, handleCredentialsResponse },
      }}
    >
      {props.children}
    </RealmAuthContext.Provider>
  )
}
RealmAuthProvider.propTypes = {}
